<template>
  <div class="bg">
    <logo />
    <video class="video" preload autoplay muted loop playsinline poster="">
        <source src="https://firebasestorage.googleapis.com/v0/b/fellpeller.appspot.com/o/background.webm?alt=media&token=ba5401fe-7000-468b-b503-03d9de7f591e" type="video/webm">
        <source src="https://firebasestorage.googleapis.com/v0/b/fellpeller.appspot.com/o/background.mp4?alt=media&token=12f75252-8433-4422-81fb-42ffe8136703" type="video/mp4">
        Sorry, your browser does not support HTML5 video.
    </video>
  </div>
</template>

<script>
import Logo from "./logo";

export default {
    name: "background",
    components: {
        logo: Logo,
    },
}
</script>

<style scoped>
.bg {
  background-color: black;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  overflow: hidden;
}
.bg > video {
  height: auto;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -2;
  animation: fade-in 2s ease 2s backwards;
}
@media (max-width: 100vh) {
  .bg > video {
    height: 100vh;
    width: auto;
  }
}
</style>