<template>
  <div class="home" @click="Enter()" @scroll="Enter()">
    <background />
    <router-link to="/about">TESTING</router-link>
  </div>
</template>

<script>
import router from "../router";
import Homepage from "../services/Homepage";
import Background from "./fellpeller/background.vue"


export default {
  name: "fellpeller",
  data() {
    return {
      movies: [],
      unsubscribe: null,
    };
  },
  components: {
    background: Background,
  },
  methods: {
    Enter() {
      router.push('/about')
    },
    onDataChange(items) {
      let _movies = [];

      items.forEach((item) => {
        let id = item.id;
        let data = item.data();
        _movies.push({
          id: id,
          name: data.name,
          img: data.img,
          logo: data.logo,
          logoSize: data.logoViewBox,
          links: data.links,
          info: data.info,
        });
      });

      this.movies = _movies;
    },
  },
  mounted() {
    this.unsubscribe = Homepage.getAll().onSnapshot(this.onDataChange);
  },
  beforeUnmount() {
    this.unsubscribe;
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}
.info {
  position: relative;
  margin-top: 100vh;
  height: auto;
  width: 100%;
  font-size: 10vh;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
}
.tile {
  height: 100vh;
}
</style>