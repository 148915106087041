import firebase from "../firebase";

const db = firebase.collection("/Homepage").orderBy("key", "desc");

class Homepage {

  getAll() {
    return db;
  }

  update(id, value) {
      return db.doc(id).update(value);
  }
  
}

export default new Homepage();